<template>
  <div class="home-page">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "home-page",
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="less"></style>
